import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Authenticate from "../container/auth/admin/login/Login";
import { createBrowserHistory } from "history";
import ForgotPassword from "../container/auth/admin/forgotPassword/ForgotPassword";
import ResetPassword from "../container/auth/admin/resetPassword/ResetPassword";
import Profile from "../container/pages/admin/profile/Profile";
import PrivayPolicy from "../container/pages/admin/cms/PrivayPolicy";
import TermsCondition from "../container/pages/admin/cms/TermsCondition";
import OtpVerify from "../container/auth/admin/otpVerify/OtpVerify";
import DashboardLayout from "../components/admin/dashboardLayout/DashboardLayout";
import Dashboard from "../container/pages/admin/dashboard/Dashboard";
import PrivateRoutes from "./PrivateRoutes";
import PageNotFound from "../components/common/PageNotFound";
import InternalServerError from "../components/common/InternalServerError";
import UserList from "../container/pages/admin/userList/UserList";
import UserDetail from "../container/pages/admin/UserDetail/UserDetail";
import WardenList from "../container/pages/admin/wardenList/WardenList";
import WardenDetail from "../container/pages/admin/wardenList/WardenDetail.js";
import EmployeeList from "../container/pages/admin/employeeList/EmployeeList";
import ChangePassword from "../container/pages/admin/ChangePassword/ChangePassword";
import CategoryManagementList from "../container/pages/admin/categoryManagementList/CategoryManagementList";
import ContactUs from "../container/pages/admin/cms/Contactus.js";
import TagsManagement from "../container/pages/admin/tagsManagement/TagsManagement";
import AboutUs from "../container/pages/admin/cms/AboutUs";
import NFTList from "../container/pages/admin/NFTList/NFTList";
import NFTAdminDetails from "../container/pages/admin/NFTList/NFTDetails";
import SubCategoryManagementList from "../container/pages/admin/subCategoryManagementList/SubCategoryManagementList";
import NFTPrivacyPolicy from "../container/pages/admin/cms/NFTPrivacyPolicy";
import NFTTermsCondition from "../container/pages/admin/cms/NFTTermsCondition";
import { LeafIdea } from "../container/pages/admin/leafIdea/LeafIdea";
import ActivityLogList from "../container/pages/admin/nftActivityLog/ActivityLogList";
import IdeaTreeView from "../container/pages/admin/ideaTreeView/IdeaTreeView";
import ContactUsList from "../container/pages/admin/contactUsList/ContactUsList";
import ContactDetail from "../container/pages/admin/contactUsList/ContactDetail";
import PFPList from "../container/pages/admin/pfpList/PFPList";
import ViewSingleLeafBranch from "../container/pages/admin/ViewSingleLeafBranch/ViewSingleLeafBranch";
import WardenSteps from "../container/pages/admin/cms/WardenSteps";
import WardenRoles from "../container/pages/admin/cms/WardenRoles";
import InviteWardenList from "../container/pages/admin/inviteWarden/InviteWardenList";
import UserRequest from "../container/pages/admin/userRequest/UserRequest";
import SocialMediaList from "../container/pages/admin/socialMediaPost/SocialMediaList";
import ReportedPostList from "../container/pages/admin/reportedPost/ReportedPostList.js";
import InappropriateList from "../container/pages/admin/inappropriateRequest/InappropriateList";
import SocialDetail from "../container/pages/admin/socialMediaPost/SocialDetail.js";
import CommunityGroupList from "../container/pages/admin/communityGroupList/CommunityGroupList";
import GroupMemberList from "../container/pages/admin/communityGroupList/GroupMemberList";
import ReportList from "../container/pages/admin/reportAndBolck/ReportList";
import ViewDocument from "../container/pages/admin/userRequest/ViewDocument";
import MasterWardenList from "../container/pages/admin/masterWarden/MasterWardenList.js";
// import AddEditMasterWarden from "../container/pages/admin/masterWarden/AddEditMasterWarden.js";
import NftIdeaList from "../container/pages/masterWarden/nftIdea/NftIdeaList.js";
import { NftDetails } from "../container/pages/masterWarden/nftIdea/NftDetails.js";
import WardenWarnig from "../container/pages/masterWarden/wardenWarning/WardenWarning.js";
import AddUser from "../container/pages/admin/userList/AddUser.js";
import TransactionList from "../container/pages/admin/transactionHistory/TransactionList.js";

import PendingTransaction from "../container/pages/admin/transactionHistory/PendingTransaction.js";

import PFPIdeaList from "../container/pages/admin/pfpList/PFPIdeaList.js";
import Notification from "../container/pages/admin/notifications/notification.js";
import ExpertIdeaList from "../container/pages/masterWarden/expertIdea/ExpertIdealist.js";
import { ExpertNftView } from "../container/pages/masterWarden/expertIdea/ExpertNftView.js";
import PlatformFees from "../container/pages/wallet/PlatformFees.js";
import SellIdea from "../container/pages/wallet/SellIdea.js";
import BuyIdea from "../container/pages/wallet/BuyIdea.js";
import MasterProcessingFee from "../container/pages/wallet/MasterProcessingFee.js";
import Success from "../container/pages/wallet/Success.js";
import PutOnSale from "../container/pages/wallet/PutOnSale.js";
import ConnectWallet from "../container/pages/wallet/ConnectWallet.js";
import DisconnectWallet from "../container/pages/wallet/DisconnectWallet.js";
import PFPupdate from "../container/pages/wallet/PFPupdate.js";
import BecameWarden from "../container/pages/wallet/BecameWarden.js";
import Backtoapp from "../container/pages/wallet/Backtoapp.js";
import WalletAddress from "../container/pages/admin/wallet-address/WalletAddress.js";
import Referrals from "../container/pages/admin/referral/Referrals.js";
import MWTransactionHistory from "../container/pages/masterWarden/TransactionHistory.js";
import AddEditRewardToken from "../container/pages/admin/rewardToken/AddEditRewardToken.js";
const history = createBrowserHistory();

const Routers = () => {
  return (
    <BrowserRouter history={history}>
      <Routes>
        {/* Auth routes */}
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/admin/login" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Authenticate />} />
        <Route path="/admin/forgot-password" element={<ForgotPassword />} />
        <Route path="/admin/reset-password" element={<ResetPassword />} />
        <Route path="/admin/otp-verify" element={<OtpVerify />} />
        <Route path="/server-error" element={<InternalServerError />} />

        {/* wallet connect routes */}
        <Route path="/wallet/processing-fee/:id" element={<PlatformFees />} />
        <Route path="/wallet/master-processing-fee/:id" element={<MasterProcessingFee />} />
        <Route path="/wallet/put-on-sale/:wallet_address/:id/:nft_id/:amount/:userId" element={<PutOnSale />} />
        {/* <Route path="/wallet/buy-idea/:user_id/:nft_id" element={<BuyIdea />} /> */}
        {/* <Route path="/wallet/sell-nft/:wallet_address/:id/:nft_id/:amount" element={<SellIdea />} /> */}
        {/* <Route path="/wallet/sell-nft/:user_id/:nft_id" element={<PlatformFees />} /> */}
        <Route path="/wallet/buy/:id/:nft_id/:user_id" element={<BuyIdea />} />
        <Route path="/wallet/success" element={<Success />} />
        <Route path="/wallet/connect" element={<ConnectWallet />} />
        <Route path="/wallet/backtoapp" element={<Backtoapp />} />
        <Route path="/wallet/disconnect" element={<DisconnectWallet />} />
        <Route path="/wallet/pfpUpdate/:ideaId/:days/:tokens" element={<PFPupdate />} />
       <Route path="/wallet/becameWarden/:wardenID/:amount" element={<BecameWarden />} />
        {/* <Route path="/wallet/sell/:nft_id/:amount" element={<SellIdea/>} /> */}

        {/* Pages routes */}
        <Route element={<PrivateRoutes />}>
          {/* Admin login routes */}
          <Route path="/admin" element={<DashboardLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="user-list" element={<UserList />} />
            <Route path="add-user" element={<AddUser />} />
            <Route path="master-warden-list" element={<MasterWardenList />} />
            {/* <Route path="add-master-warden" element={<AddEditMasterWarden />} /> */}
            <Route path="user-detail/:id" element={<UserDetail />} />
            <Route path="warden-list" element={<WardenList />} />
            <Route path="warden-detail/:id" element={<WardenDetail />} />
            <Route path="employee-list" element={<EmployeeList />} />
            <Route path="update-profile" element={<Profile />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="category-management" element={<CategoryManagementList />} />
            <Route path="tags-management" element={<TagsManagement />} />
            <Route path="sub-category/:categoryName/:categoryId" element={<SubCategoryManagementList />} />
            <Route path="nft-list" element={<NFTList />} />
            <Route path="nft-details/:id" element={<NFTAdminDetails />} />
            <Route path="contact-us-list" element={<ContactUsList />} />
            <Route path="contact-detail/:id" element={<ContactDetail />} />
            <Route path="activity-log-list" element={<ActivityLogList />} />
            <Route path="idea-tree-view" element={<IdeaTreeView />} />
            <Route path="warden-roles" element={<WardenRoles />} />
            <Route path="warden-steps" element={<WardenSteps />} />
            <Route path="leaf-idea" element={<LeafIdea />} />
            <Route path="view-single-leaf" element={<ViewSingleLeafBranch />} />
            <Route path="view-document" element={<ViewDocument />} />
            <Route path="pfp-list" element={<PFPList />} />
            <Route path="report-list" element={<ReportList />} />
            <Route path="social-list" element={<SocialMediaList />} />
            <Route path="social-detail/:id" element={<SocialDetail />} />
            <Route path="reported-social-detail/:id" element={<SocialDetail />} />
            <Route path="reported-posts" element={<ReportedPostList />} />
            <Route path="group-list" element={<CommunityGroupList />} />
            <Route path="group-member-list" element={<GroupMemberList />} />
            <Route path="nft-terms-and-condition" element={<NFTTermsCondition />} />
            <Route path="expert-ideas" element={<ExpertIdeaList />} />
            <Route path="expert-nft-details/:id" element={<ExpertNftView />} />
            <Route path="kyc-request-list" element={<UserRequest />} />
            <Route path="invite-warden-list" element={<InviteWardenList />} />
            <Route path="nft-privacy-policy" element={<NFTPrivacyPolicy />} />
            <Route path="terms-and-condition" element={<TermsCondition />} />
            <Route path="privacy-policy" element={<PrivayPolicy />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="setting-wallet-address" element={<WalletAddress />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="inappropriate-request" element={<InappropriateList />} />
            <Route path="transaction-history" element={<TransactionList />} />
            <Route path="pending-transaction" element={<PendingTransaction />} />          
            <Route path="warning-list" element={<WardenWarnig />} />
            <Route path="pfp-ideas" element={<PFPIdeaList />} />
            <Route path="pfp-details" element={<NFTAdminDetails />} />
            <Route path="notification" element={<Notification />} />
            <Route path="referrals" element={<Referrals />} />
            <Route path="reward-token" element={<AddEditRewardToken />} />
          </Route>
        
          {/* Warden login routes */}
          <Route path="/warden" element={<DashboardLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="nft-list" element={<NftIdeaList />} />
            <Route path="update-profile" element={<Profile />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="nft-details/:id" element={<NftDetails />} />
            <Route path="warden-detail/:id" element={<WardenDetail />} />
            <Route path="warden-list" element={<UserList />} />
            <Route path="warning-list" element={<WardenWarnig />} />
            <Route path="pfp-ideas" element={<PFPIdeaList />} />
            <Route path="expert-ideas" element={<ExpertIdeaList />} />
            <Route path="expert-nft-details/:id" element={<ExpertNftView />} />
            <Route path="reported-posts" element={<ReportedPostList />} />
            <Route path="transaction-history" element={<MWTransactionHistory />} />
            <Route path="reported-social-detail/:id" element={<SocialDetail />} />
          </Route>
        </Route>
        {/* 404 - Page not found */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
