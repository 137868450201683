import React, { useEffect, useState } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import PagesIndex from "../../PagesIndex";
import WalletLoader from "../../../components/common/WalletLoader";
const projectId = "2fa6c12dde1b5cd1a88e4fd4cb690bca";

const PolygonMainnet = {
  chainId: 137,
  name: "Polygon Mainnet",
  currency: "MATIC",
  explorerUrl: "https://polygonscan.com/",
  rpcUrl:
    "https://polygon-mainnet.infura.io/v3/8c79ca71179c4e318999034a96a93f31",
};

const metadata = {
  name: "AAAA.io",
  description: "cc.io",
  url: "https://episapient.appworkdemo.com/",
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [PolygonMainnet],
  projectId,
  enableAnalytics: true,
  featuredWalletIds: [
    "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  ],
  allWallets: "ONLY_MOBILE",
});

export default function ConnectWallet() {
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const { address } = useWeb3ModalAccount();
  const [isVisible, setIsVisible] = useState(true);
  const themeColor = new URLSearchParams(location.search).get("mode");

  async function handleSell(price, tokenId) {
    navigate("/wallet/address/" + address);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div
        className={`${
          themeColor === "dark"
            ? "wallet-box wallet-box-content-dark"
            : "wallet-box wallet-box-content"
        }`}
      >
        <div className="wallet-box-cont">
          <div className="logo-box">
            <img src={PagesIndex.Png.logo2} alt="" />
            <div className="logo-title">EPISAP</div>
          </div>
          <w3m-button />
          <div className="wallet-btn-approve">
            {address && (
              <button onClick={() => handleSell()}>Click To Connect</button>
            )}
          </div>
        </div>
      </div>
      {isVisible && (
        <div className="wallet-loader">
          <WalletLoader />
        </div>
      )}
    </>
  );
}
