import usericon from "./images/png/user.png";
import profileimg from "./images/png/profileimg.png";
import arrow from "./images/png/arrow.png";
import card1 from "./images/png/card1.png";
import card2 from "./images/png/card2.png";
import card3 from "./images/png/card3.png";
import card4 from "./images/png/card4.png";
import invoice from "./images/png/invoice.png";
import invoice2 from "./images/png/invoice2.png";
import like from "./images/png/like.png"
import dislike from "./images/png/dislike.png"
import pagenotfound from "./images/png/pagenotfound.png";
import episap from './images/png/episap.png'
import logo from './images/png/black-logo.png'
import AllViews from "./images/png/users.png";
import arrowBack from "./images/png/arrow-back.png";
import people from "./images/png/people.png";
import nft from "./images/png/nft.png";
import dashUser from "./images/png/dash-user.png";
import tagged from "./images/png/tagged.png";
import contactList from './images/png/contact-book.png'
import blockUser from './images/png/block-user.png'
import warden from './images/png/warden.png'
import employee from './images/png/employee.png'
import categories from './images/png/categories.png'
import tag from './images/png/tag.png'
import nftList from './images/png/nftList.png'
import blackWarden from './images/png/black-warden.png'
import warning from './images/png/warning.png'
import warning1 from './images/png/warning (1).png'
import warningList from './images/png/warning list.png'
import profile from './images/png/profile.png'
import addcategorynew from './images/png/addcategorynew.png'
import resetIcon from './images/png/resetIcon.png'
import LogoName from './images/png/LogoName.png'
import newLogo from './images/png/newLogo.png'
import logo2 from './images/png/logo2.png'
import mp4Icon from './images/png/mp4Icon.png'
import warningBlack from './images/png/warningBlack.png'
import notWarningAlert from './images/png/notWarningAlert.png'
import internalServerErrorStatusCode from './images/png/500-status-code.png'

const Png = {
  // user_logo,
  AllViews,
  tagged,
  dashUser,
  nft,
  arrowBack,
  people,
  invoice,
  invoice2,
  newLogo,
  logo2,
  card1,
  like,
  dislike,
  card2,
  card3,
  card4,
  usericon,
 pagenotfound,
  arrow,
  profileimg,
  episap,
  logo,
  contactList,
  blockUser,
  warden,
  employee,
  categories,
  tag,
  nftList,
  blackWarden,
  warning,
  warning1,
  warningList,
  profile,
  addcategorynew,
  resetIcon,
  LogoName,
  mp4Icon,
  warningBlack,
  notWarningAlert,
  internalServerErrorStatusCode,
};

export default Png;
