import React, { useEffect } from 'react';
import PagesIndex from '../../PagesIndex';

export default function Backtoapp() {
  const location = PagesIndex.useLocation();
  const themeColor = new URLSearchParams(location.search).get("mode");

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location = "episapient://";
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div 
      //className="wallet-box"
      className={`${
        themeColor === "dark"
          ? "wallet-box wallet-box-content-dark"
          : "wallet-box wallet-box-content"
      }`}
      >
        <div className="wallet-box-cont">
          <div className="logo-box">
            <img src={PagesIndex.Png.logo2} alt="" />
            <div className="logo-title">Episap</div>
          </div>

          <div className='wallet-title'>
            Your transaction is completed. Please go back to your app.
          </div>
        </div>
      </div>

    </>

  )
}
