import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import tokenAbi from "../../Connectivity/abi/tokenAbi.json";
import marketplaceAbi from "../../Connectivity/abi/marketplace.json";
import CircularProgress from "@mui/material/CircularProgress";
import {
  buyIdea,
  buyPriceAddress,
  buySellTransactionHash,
} from "../../../redux/admin/action";
import PagesIndex from "../../PagesIndex";
import WalletLoader from "../../../components/common/WalletLoader";

export default function BuyIdea() {
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const themeColor = new URLSearchParams(location.search).get("mode");

  const [priceArray, setPriceArray] = useState([]);
  const [sumTotal, setSumTotal] = useState([]);
  const [addressArray, setAddressArray] = useState([]);
  const { walletProvider } = useWeb3ModalProvider();
  const { id, nft_id, user_id } = PagesIndex.useParams();
  const [buyData, setBuyData] = useState([]);
  const [processingLog, setProcessingLog] = useState();
  const [isVisible, setIsVisible] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let values = { ideaId: id };
    buyPriceAddress(values).then((res) => {
      // console.log("response", res);
      // let sumVal = 0;
      // res?.data?.ideaAmount.forEach((value) => {
      //   console.log(value, 'value');
        
      //   sumVal += value
      // });
      // sumVal = parseFloat(sumVal.toFixed(3));
      setSumTotal(res?.data?.price);
      setBuyData(res?.data);
      setPriceArray(res?.ideaAmount);
      setAddressArray(res?.walletAddress);
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const { isConnected, address } = useWeb3ModalAccount();
  // console.log(isConnected, ' = isConnected');
  async function handleBuy(priceArray, addressarray) {
    setLoading(true);
    setProcessingLog("approving the transaction...");
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const balance = await signer.getBalance();
    const gasPrice = await provider.getGasPrice();
    let gasCost;

    const tokenContract = new ethers.Contract(
      "0x3C1e77fb138a2448153008bBe69803285c38ce96",
      tokenAbi,
      signer
    );
    setProcessingLog("performing smart contract transaction...");
    const contract = new ethers.Contract(
      "0xD5Dd555d1B6EB4e2C547F1ceE9007d475cB566A2",
      marketplaceAbi,
      signer
    );

    try {
      // let sum ;
      // buyData.ideaAmount.forEach((value) => {
      //   sum += parseFloat(value);
      // });
      // sum = buyData.ideaAmount.reduce((agr, sum) => {
      //   return agr + sum;
      // })

      if (Number(balance.toString()) === 0) {
        setProcessingLog("You have insufficient MATIC balance in your wallet");
        setLoading(false);
        return;
      } else {
        setProcessingLog("");
      }

      const activeNFT = await contract.marketItem(nft_id);
      if (activeNFT.sellActive === false) {
        alert(`This NFT is not on sell`);
        setProcessingLog("This NFT is not on sell");
        setLoading(false);
        return;
      }

      let sum = ethers.constants.Zero;
      buyData.ideaAmount.forEach((value) => {
        sum = sum.add(ethers.utils.parseEther(value.toString()));
      });

      const tokenBalance = await tokenContract.balanceOf(address);
      if (Number(tokenBalance.toString()) < Number(sum.toString())) {
        alert(`You have insufficient token balance`);
        setProcessingLog("You have insufficient token balance");
        setLoading(false);
        return;
      }

      const approveEstimatedGasLimit = await tokenContract.estimateGas.approve(
        "0xD5Dd555d1B6EB4e2C547F1ceE9007d475cB566A2",
        sum.toString()
      );
      gasCost = gasPrice.mul(approveEstimatedGasLimit);
      if (balance.lt(gasCost)) {
        setProcessingLog(
          "You have insufficient gas fees for approve transaction"
        );
        setLoading(false);
        return;
      }
      // const totaltoWei = ethers.utils.parseEther(sum.toString());
      const tx = await tokenContract.approve(
        "0xD5Dd555d1B6EB4e2C547F1ceE9007d475cB566A2",
        sum.toString()
      );
      await tx.wait();

      let pricesWeiArray = buyData.ideaAmount.map((value) => {
        // const fixedValue = parseFloat(value).toFixed(18);
        return ethers.utils.parseEther(value.toString());
      });

      let buyEstimatedGasLimit = await contract.estimateGas.buyIdea(
        nft_id,
        buyData.walletAddress,
        pricesWeiArray
      );
      gasCost = gasPrice.mul(buyEstimatedGasLimit);
      if (balance.lt(gasCost)) {
        setProcessingLog(
          "You have insufficient gas fees for buy transaction"
        );
        setLoading(false);
      }

      let platformfee = await contract.buyIdea(
        nft_id,
        buyData.walletAddress,
        pricesWeiArray
      );
      setProcessingLog("Approving smart contract transaction...");
      const data = await platformfee.wait();

      // const txReceipt = await provider.getTransactionReceipt(platformfee.hash);
      if (data.status === 1) {
        if (platformfee.hash) {
          // Transaction hash save
          let transactionHashData = {
            type: "Buy",
            nftId: id,
            userId: user_id,
            transactionHash: platformfee.hash,
            approvedTransactionHash: tx.hash,
          };
          buySellTransactionHash(transactionHashData);

          let tx_hash = platformfee.hash;
          let values = {
            nftId: id,
            tx_hash: tx_hash,
            price: parseFloat(ethers.utils.formatEther(sum.toString())),
            user_id: user_id,
            token_id: nft_id,
          };
          setProcessingLog("Executing api calls...");
          const timeOut = setTimeout(() => {
            buyIdea(values)
              .then((res) => {
                setProcessingLog("Payment done. Go Back to your app. 201");
                setLoading(false);
                navigate("/wallet/success");
                // setProcessingLog("");
              })
              .catch((error) => {
                console.log(error);
                setLoading(false);
                alert(error?.message);
              });
          }, 1000);
          return () => clearTimeout(timeOut);
        } else {
          setLoading(false);
          alert("Transaction is not completed. please try again");
        }
      } else {
        setLoading(false);
        alert("Transaction failed");
      }
    } catch (error) {
      setLoading(false);
      alert(error?.message);
      // console.log("error?.message", error?.message);
      // console.error("Error while approving:", error);
    }
  }

  // 10 Min timer
  const [timeLeft, setTimeLeft] = useState(600); // 600 seconds = 10 minutes
  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer); // Clean up the interval on component unmount
    } else {
      setTimeLeft(600);
    }
  }, [loading]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <>
      <div
        //className="wallet-box"
        className={`${
          themeColor === "dark"
            ? "wallet-box wallet-box-content-dark"
            : "wallet-box wallet-box-content"
        }`}
      >
        <div className="wallet-box-cont">
          <div className="logo-box">
            <img src={PagesIndex.Png.logo2} alt="" />
            <div className="logo-title">EPISAP</div>
          </div>
          <w3m-button />
          <h4 className="wallet-title">
            Buy NFT Idea <br />
            {sumTotal} VHT
          </h4>
          <div className="wallet-btn-approve">
            <button
              onClick={() =>
                handleBuy(buyData?.ideaAmount, buyData?.walletAddress)
              }
              disabled={!isConnected || loading}
              className={`loader-white ${!isConnected ? "disable-color" : ""}`}
            >
              {loading == true ? <CircularProgress size={16} /> : "Buy"}
            </button>
          </div>
          <div className="wallet-subtitle">{processingLog}</div>
          {loading && (
            <div className="payment-timer">
              <p>
                <strong>
                  The payment is still processing. Please don't close the app or
                  turn off your screen. Wait until you get a response from the
                  wallet. If you don't, you might lose your data.
                </strong>
              </p>
              <h3>Countdown Timer</h3>
              <div style={{ fontSize: "1.5rem" }}>{formatTime(timeLeft)}</div>
            </div>
          )}
        </div>
      </div>
      {isVisible && (
        <div className="wallet-loader">
          <WalletLoader />
        </div>
      )}
    </>
  );
}
