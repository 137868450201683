import React from "react";
import PagesIndex from "../../PagesIndex";

export default function Success() {
  const location = PagesIndex.useLocation();
  const themeColor = new URLSearchParams(location.search).get("mode");
  return (
    <div
      className={`${
        themeColor === "dark"
          ? "wallet-box wallet-box-content-dark"
          : "wallet-box wallet-box-content"
      }`}
    >
      <div className="wallet-success-msg">
        Your transaction is completed we are redirecting back to the app...
      </div>
    </div>
  );
}
